/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(".vimeo-iframe").on("play", function(){
          var parentEl = $(this).parents('.featured-post-wrapper');
          $('.featured-content', parentEl).fadeOut();
        }).on("pause", function(){
          var parentEl = $(this).parents('.featured-post-wrapper');
          $('.featured-content', parentEl).fadeIn();
        });

        if ($('select#month-filter').length > 0) {
          $('select#month-filter').change(function(){
            window.location = $(this).val();
          });
          var optionExists = 0 != $('select#month-filter option[value="'+window.location.href+'"]').length;
          if (optionExists) {
            $('select#month-filter').val(window.location.href);
          }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page template
    'page_template_template_about': {
      init: function() {

      }
    },
    // Podcasts
    'post_type_bh_podcast': {
      init: function() {

      }
    },
    // Jolt archives
    'post_type_archive_jolt': {
      init: function() {
        $(document).on('mouseenter', '.archive-jolt', function(){
          $('.share-jolt-links', this).fadeIn();
        });

        $(document).on('mouseleave', '.archive-jolt', function(){
          $('.share-jolt-links', this).fadeOut();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
